import styled from "styled-components";

export const Socialcircle = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #fff;
`

export const Line = styled.div`
    border-left: 1px solid white;
    top: 0;
    height: 25px;
    margin: 0 5px 0 5px;
`