import styled from "styled-components";
import { Colors } from "../../UiConstants";
import Box from "../../Flex/Box";

export const InnerCard = styled(Box)`
background: ${Colors.DIM_ORANGE};
height: 80px;
width: 75%;
border-radius: 50px;
position: relative;
display: flex;
align-items: center;

@media screen and (max-width: 600px) {
    width: 80%;
}
`

export const Cicle = styled(Box)`
display: flex;
width: 125px;
height: 125px;
border-radius: 50%;
background: #984B13;
position: absolute;
border: 2px solid white;
z-index: 1;
align-items: center;
justify-content: center;

@media screen and (max-width: 600px) {
    width: 100px;
    height: 100px;
}
`

export const Line = styled(Box)`
border-left: 1px solid white;
top: 0;
height: 35px;
margin: 0 0px 0 20px;
`