import Flex from '../../Flex'
import { Colors, fontWeight } from '../../UiConstants'
import { Text } from '../../Text'
import footerLogo from '../../../assets/petPal white.png'
import twitterIcon from '../../../assets/twitterLogo.png'
import telegramIcon from '../../../assets/telegramLogo.png'
import { Line, Socialcircle } from './style'

const Footer = () => {
    return (
        <Flex width={"100%"} mt={"150px"} background={Colors.ORANGE}>
            <Flex width={'90%'} margin={"auto"} flexDirection={"column"} mb={'20px'}>
                <Flex mt={'50px'} justifyContent={"space-between"}>
                    <Flex flexDirection={'column'}>
                        <Text fontSize='20px' fontWeight={fontWeight.SEMI_BOLD}>Connect With Us</Text>
                        <Flex mt={'25px'}>
                            <Flex alignItems={'center'}>
                                <Socialcircle><img style={{ margin: "5px auto", display: "block" }} src={twitterIcon} /></Socialcircle>
                                <Flex ml={'20px'}><Socialcircle><img style={{ margin: "5px 5px", display: "block" }} src={telegramIcon} /></Socialcircle></Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex>
                        <img src={footerLogo} />
                    </Flex>
                </Flex>
                <Flex justifyContent={"space-between"} mt={'120px'}>
                    <Flex>
                        <Text fontSize='16px' fontWeight={fontWeight.SEMI_BOLD}>Terms</Text>
                        <Line />
                        <Text fontSize='16px' fontWeight={fontWeight.SEMI_BOLD}>Privacy Policy</Text>
                    </Flex>
                    <Text fontSize='16px' fontWeight={fontWeight.SEMI_BOLD}>©2024 Petpal All rights reserved</Text>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default Footer