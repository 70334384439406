import React, { useEffect, useState } from 'react'
import "./slider.css";
import Flex from '../Flex';
import leftArrow from '../../assets/leftArrow.png'
import rightArrow from '../../assets/rightArrow.png'

const CustomSlider = ({ children }: any) => {
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [slideDone, setSlideDone] = useState<boolean>(true);
    const [timeID, setTimeID] = useState<any>(null);

    useEffect(() => {
        if (slideDone) {
            setSlideDone(false);
            setTimeID(
                setTimeout(() => {
                    slideNext();
                    setSlideDone(true);
                }, 5000)
            );
        }
    }, [slideDone]);

    const slideNext = () => {
        setActiveIndex((val) => {
            if (val >= children.length - 1) {
                return 0;
            } else {
                return val + 1;
            }
        });
    };

    const slidePrev = () => {
        setActiveIndex((val) => {
            if (val <= 0) {
                return children.length - 1;
            } else {
                return val - 1;
            }
        });
    };

    const AutoPlayStop = () => {
        if (timeID > 0) {
            clearTimeout(timeID);
            setSlideDone(false);
        }
    };

    const AutoPlayStart = () => {
        if (!slideDone) {
            setSlideDone(true);
        }
    };

    return (
        <>
            <Flex justifyContent={"center"} alignItems={"center"} marginLeft={"30px"} onClick={slidePrev}><img width={"15px"} height={"25px"} src={leftArrow} /></Flex>
            <div
                className="container__slider"
                onMouseEnter={AutoPlayStop}
                onMouseLeave={AutoPlayStart}
            >
                {children.map((item: any, index: any) => {
                    return (
                        <div
                            className={"slider__item slider__item-active-" + (activeIndex + 1)}
                            key={index}
                        >
                            {item}
                        </div>
                    );
                })}

                <div className="container__slider__links">
                    {children.map((item: any, index: any) => {
                        return (
                            <button
                                key={index}
                                className={
                                    activeIndex === index
                                        ? "container__slider__links-small container__slider__links-small-active"
                                        : "container__slider__links-small"
                                }
                                onClick={(e) => {
                                    e.preventDefault();
                                    setActiveIndex(index);
                                }}
                            >
                            </button>
                        );
                    })}
                </div>
            </div>
            <Flex justifyContent={"center"} alignItems={"center"} marginRight={"30px"} onClick={slideNext}><img width={"15px"} height={"25px"} src={rightArrow} /></Flex>
        </>
    );
}

export default CustomSlider