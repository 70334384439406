import Flex from '../../Flex'
import { Text } from '../../Text'
import { Colors, fontWeight } from '../../UiConstants'
import CustomSlider from '../../slider/customSlider'
import sliderData from '../../slider/sliderData'

const HowItWorks = () => {
    const properties = {
        fontWeight: fontWeight.BOLD,
        fontSize: "40px",
    }
    return (
        <Flex width={"90%"} margin={"auto"} flexDirection={"column"} mt={"120px"}>
            <Flex justifyContent={"center"}><Text style={properties}>How It <span style={{ color: Colors.ORANGE }}>Works</span></Text></Flex>
            <Flex width={"100%"} height={"100%"} justifyContent={"space-between"} className='BG' mt={"60px"}>
                <Flex flexDirection={'row'} alignItems={"center"}>
                    <CustomSlider>
                        {sliderData.map((data, index) => {
                            return (
                                <>
                                    <Flex flexDirection={'row'} alignItems={'center'} justifyContent={'space-around'}>
                                        <Flex><img key={index} src={data.imgURL} alt={data.imgAlt} /></Flex>
                                        <Text fontSize='32px' fontWeight={fontWeight.LIGHT} textAlign={"center"}>{data.text}</Text>
                                    </Flex>
                                </>
                            )
                        })
                        }</CustomSlider>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default HowItWorks