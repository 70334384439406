import './App.css';
import Flex from './components/Flex';
import petPalLogo from './assets/petpal-logo.png'
import Intro from './components/views/intro';
import light from './assets/petpal-light.png';
import HowItWorks from './components/views/howitworks';
import PetMarketPlace from './components/views/petmarketplace';
import IndustryPotential from './components/views/industrypotential';
import Roadmap from './components/views/roadmap';
import Footer from './components/views/footer';

function App() {
  return (
    <div className='App'>
      <Flex className="App-header">
        <Flex position={'absolute'} className='app-Light'><img src={light} alt='light' /></Flex>
        {<Flex position={'absolute'} backgroundSize={'100vw 100%'} className='App-herolines' />}
        <Flex m={"80px 0 0 92px"} flexDirection={'column'}>
          <Flex>
            <img width={'256px'} height={'98px'} src={petPalLogo} alt='logo' />
          </Flex>
          <Intro />
        </Flex>
      </Flex>
      <HowItWorks />
      <PetMarketPlace />
      <IndustryPotential />
      <Roadmap />
      <Footer />
    </div>
  );
}

export default App;
