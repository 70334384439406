import { useState } from 'react'
import Flex from '../../Flex'
import { Text } from '../../Text/index'
import tryDemoButton from '../../../assets/demoButton.svg'
import petsMainIcon from '../../../assets/petsMain.svg'
import { Colors, fontWeight } from '../../UiConstants'

const Intro = () => {
    const [hovered, setIsHovered] = useState<any>(false)
    return (
        <Flex justifyContent={"space-between"}>
            <Flex flexDirection={'column'} zIndex={1}>
                <Flex flexDirection={"row"} mt={"50px"}>
                    <Text fontSize='62px' fontWeight={fontWeight.BOLD}>Your <span style={{ color: Colors.ORANGE }}>Pet</span> in the Digital Realm</Text>
                </Flex>
                <Text fontSize='20px' fontWeight={fontWeight.MEDIUM} mt={'30px'}>Preserve your pets forever in the digital world with AI and Blockchain</Text>
                <Flex mt={'17px'} ml={"-45px"} className={hovered && 'btn-mover'}>
                    <img onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} src={tryDemoButton} width={'300px'} height={'150px'} alt='button-demo' />
                </Flex>
            </Flex>
            <Flex zIndex={1}>
                <img src={petsMainIcon} alt='pets' />
            </Flex>
        </Flex>
    )
}

export default Intro