import Flex from '../../Flex'
import { Text } from '../../Text'
import { Colors, fontWeight } from '../../UiConstants'

interface Card {
    img: string,
    mainHeading: string,
    subHeading: string,
}

const IndustryCards = ({ img, mainHeading, subHeading }: Card) => {
    return (
        <Flex style={{ backgroundColor: Colors.DIM_ORANGE }} width={"90%"} height={"150px"} margin={"auto"} mb={"45px"} borderRadius={"16px"}>
            <Flex flexDirection={'row'} alignItems={"center"} ml={"25px"}>
                <img width={"100px"} height={"100px"} src={img} />
                <Flex flexDirection={'column'} ml={"35px"}>
                    <Text fontSize='32px' fontWeight={fontWeight.BOLD}>{mainHeading}</Text>
                    <Text fontSize='24px' fontWeight={fontWeight.NORMAL}>{subHeading}</Text>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default IndustryCards