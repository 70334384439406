import Flex from '../../Flex'
import sideLinesIndustry from '../../../assets/sideLinesIndustry.png'
import industryValuationGraph from '../../../assets/industryValuationGraph.svg'
import stars from '../../../assets/stars.png'
import IndustryCards from './industryCards'
import solanaIcon from '../../../assets/solanaIcon.png'
import AiIcon from '../../../assets/AI-icon.png'
import mobileNFTicon from '../../../assets/mobileNFT-icon.png'
import petPawIcon from '../../../assets/petPaw-icon.png'
import { Text } from '../../Text'
import { Colors, fontWeight } from '../../UiConstants'

const IndustryPotential = () => {
    return (
        <Flex width={"100%"} margin={"auto"} mt={"150px"} flexDirection={'column'}>
            <Flex width={"90%"} margin={"auto"} justifyContent={'center'} flexDirection={'column'} alignItems={'center'}>
                <Text mb={'60px'} fontSize='40px' fontWeight={fontWeight.BOLD} textAlign={'center'}>How <span style={{ color: Colors.ORANGE }}>PetPal</span> fits in the following industries...</Text>
                <img src={industryValuationGraph} width={"100%"} height={"100%"} />
            </Flex>
            <Flex justifyContent={"space-between"}>
                <Flex mt={"40px"}>
                    <img src={sideLinesIndustry} />
                </Flex>
                <Flex mt={"80px"} mr={"5%"}>
                    <img width={"145px"} height={"110px"} src={stars} />
                </Flex>
            </Flex>
            <Flex flexDirection={'column'}>
                <IndustryCards img={solanaIcon} mainHeading="Solana DePIN" subHeading="A personalized blockchain based nametag for your pet" />
                <IndustryCards img={AiIcon} mainHeading="AI Industry" subHeading="Process images of animals into 3D models" />
                <IndustryCards img={mobileNFTicon} mainHeading="NFTs" subHeading="Unique identification of your  pet on the Blockchain" />
                <IndustryCards img={petPawIcon} mainHeading="Pets Industry" subHeading="Your pet always with you in the physical and digital world" />
            </Flex>
        </Flex>
    )
}

export default IndustryPotential