import Flex from '../../Flex'
import { Text } from '../../Text'
import { fontWeight, fontFamily } from '../../UiConstants'
import { Cicle, InnerCard, Line } from './style'

interface cardsProps {
    headNumber: string,
    subHead1: string,
    subHead2?: string,
    shift?: boolean
}

const Cards = ({ headNumber, subHead1, subHead2, shift = true }: cardsProps) => {
    return (
        <Flex alignItems={'center'} justifyContent={shift ? 'flex-start' : 'flex-end'} mt={'35px'}>
            <Cicle>
                <Text fontFamily={fontFamily.PLUS_JAKARTA} fontSize={'60px'} fontWeight={fontWeight.BOLD} className='headNumber'>{headNumber}</Text>
            </Cicle>
            <InnerCard>
                <Text fontFamily={fontFamily.PLUS_JAKARTA} fontSize='24px' fontWeight={fontWeight.LIGHT} paddingLeft={shift ? '160px' : "50px"} className='subHeading'>{subHead1}</Text>
                {subHead2 && <>
                    <Line />
                    <Text fontFamily={fontFamily.PLUS_JAKARTA} fontSize='24px' fontWeight={fontWeight.LIGHT} paddingLeft={"20px"} className='subHeading'>{subHead2}</Text>
                </>
                }
            </InnerCard>
        </Flex>
    )
}

export default Cards