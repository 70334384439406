export const enum Colors {
    WHITE = "#FFF",
    ORANGE = "#E66202",
    DIM_ORANGE = "#291c14"
}

export const enum fontWeight {
    LIGHT = 300,
    NORMAL = 400,
    MEDIUM = 500,
    SEMI_BOLD = 600,
    BOLD = 700,
    EXTRA_BOLD = 800
}

export const enum fontFamily {
    MONTSERRAT = "Montserrat",
    PLUS_JAKARTA = "Plus Jakarta Sans"
}