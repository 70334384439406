import Frame1 from '../../assets/how_it_works_1.png'
import Frame2 from '../../assets/how_it_works_2.png'
import Frame3 from '../../assets/how_it_works_3.png'

const sliderData = [
    {
        imgURL: Frame1,
        imgAlt: "img-1",
        text: "Scan and digitize your pet using AI to generate a 3D model"
    },
    {
        imgURL: Frame2,
        imgAlt: "img-2",
        text: "Generate a NFT that stores the 3D model of your pet"
    },
    {
        imgURL: Frame3,
        imgAlt: "img-3",
        text: "Link your pet's digital clone with their personalized PetPal nametag"
    }
]

export default sliderData