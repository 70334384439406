import Flex from '../../Flex'
import { Text } from '../../Text'
import { Colors, fontWeight } from '../../UiConstants'
import pet_marketPlace from '../../../assets/pet_marketPlace.svg'
import pet_babysitting_platform from '../../../assets/pet_babysitting_platform.svg'
import pet_accessory_marketplace from '../../../assets/pet_accessory_marketplace.svg'
import digital_pet_realm from '../../../assets/digital_pet_realm.svg'

const PetMarketPlace = () => {
    return (
        <Flex width={"90%"} margin={"auto"} mt={"150px"} flexDirection={"column"}>
            <Flex justifyContent={"center"}>
                <Text fontSize='40px' fontWeight={fontWeight.BOLD}>
                    What's next for your <span style={{ color: Colors.ORANGE }}>PetPals?</span>
                </Text>
            </Flex>
            <Flex mt={"60px"} width={"100%"} className='marketplace_cards'>
                <Flex width={"100%"} justifyContent={"space-around"} className='space'>
                    <img src={pet_marketPlace} />
                    <img src={pet_babysitting_platform} />
                </Flex>
                <Flex width={"100%"} justifyContent={"space-around"} className='space'>
                    <img src={pet_accessory_marketplace} />
                    <img src={digital_pet_realm} />
                </Flex>
            </Flex>
        </Flex>
    )
}

export default PetMarketPlace