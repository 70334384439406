import { Text } from '../../Text'
import Flex from '../../Flex'
import { fontWeight } from '../../UiConstants'
import Cards from './cards'
import stars from '../../../assets/stars.png'

const Roadmap = () => {
    return (
        <Flex width={"90%"} margin={"auto"} mt={"50px"} flexDirection={"column"}>
            <img width={'140px'} height={'110px'} src={stars}/>
            <Flex justifyContent={"center"} mb={'20px'}>
                <Text fontSize='48px' fontWeight={fontWeight.BOLD}>
                    Roadmap
                </Text>
            </Flex>
                <Cards headNumber='01' subHead1='Launch the Beta Version of PetPal Mobile Application' />
                <Cards headNumber='02' subHead1='Introduce Pet NFT Marketplace' subHead2='Fair Token Launch' shift={false} />
                <Cards headNumber='03' subHead1='Release Personalized PetPal Nametag' subHead2='Kick Off Pet Babysitting Platform' />
                <Cards headNumber='04' subHead1='Unveil Digital Pet Realm ' subHead2='Launch Pet Accessory Marketplace' shift={false} />
        </Flex>
    )
}

export default Roadmap